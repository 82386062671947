<template>
  <div class="error-massage-cont" :width="width" :style="styles" :class="classes">
    <span class="mdi mdi-alert-outline"></span>
    <p>{{ message }}</p>
  </div>
</template>

<script>
export default {
  name: "ErrorMessageContainer",
  props: {
    message: {
      type: String,
      default: "Error Message"
    },
    width: {
      type: [String, Number],
      default: ''
    },

    styles: {
      type: Object,
      default: null
    },

    classes: {
      type: String,
      default: '' 
    }
  }
};
</script>
<style lang="scss" scoped>
@import "./src/assets/scss/authGeneral";
@import "./src/assets/scss/variables";
.error-massage-cont {
  display: flex;
  color: $danger-color-dark;
  background-color: $danger-color9;
  border-radius: $border-radius;
  padding: 6px 12px;
  align-items: center;
  margin-bottom: 20px;
  p {
    margin-bottom: 0;
    margin-left: 14px;
    font-size: $txt14;
    text-wrap: wrap !important;
    width: 92%;
  }
  .mdi-alert-outline {
    font-size: 20px;
  }
}
  .theme--dark{
    .error-massage-cont{
      background-color: $danger-rgb;
      color: $white;
    }
  }
</style>
