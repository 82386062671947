<template>
  <div class="auth-cont" @keypress.enter="logIn">
    <div>
      <auth-header />
      <div class="auth-body">
        <h5 class="title">Sign in </h5>

        <ott-input
          v-model="form.email"
          :error="$v.form.email.$error"
          label="Username"
          customInputClass="mb-4 username"
        />

        <password-input
          :error="$v.form.password.$error"
          @emitFunc="validationFunc('password', $event)"
          customInputClass="password"
        />
        <router-link to="/auth/password-assistance" class="forgotPass"
          >Forgot Password ?</router-link
        >

        <error-message-container v-if="isShowMessage" :message="message" />
        <OttButtonLoader
          :is-loading="isLoading"
          @onClick="logIn"
          button-text="Sign in"
          className="primary-lg-btn P-login-btn"
        />
        <router-link to="/auth/sign-up" class="signUpText"
          >Sign up for an account</router-link
        >
      </div>
    </div>
    <auth-footer />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import AuthFooter from "./components/authFooter";
import AuthHeader from "./components/authHeader";
import OttInput from "@/components/vuetifyComponents/OttInput";
import PasswordInput from "@/components/PasswordInput";
import OttButton from "@/components/vuetifyComponents/OttButton";
import ErrorMessageContainer from "@/components/customComponents/ErrorMessageContainer";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";

export default {
  name: "UserSignIn",
  components: {
    OttButtonLoader,
    ErrorMessageContainer,
    OttButton,
    PasswordInput,
    OttInput,
    AuthHeader,
    AuthFooter
  },
  validations: {
    form: {
      email: { required },
      password: { required, minLength: minLength(8) }
    }
  },
  data() {
    return {
      form: {
        email: "",
        password: ""
      },
      userNameLabel: 'Username',
      isShowMessage: false,
      message: ""
    };
  },
  computed: {
    ...mapState({
      isLoading: state => state.appModule.isLoading
    })
  },

  mounted() {
    const userName = document.querySelector('.username')
    const userNameLabel = userName.querySelector('label')
    userNameLabel.classList.add('v-label--active', 'bg-white-label')


    const password = document.querySelector('.password')
    const passwordLabel = password.querySelector('label')
    passwordLabel.classList.add('v-label--active', 'bg-white-label')
  },
  methods: {
    ...mapActions({
      loginAction: "auth/login"
    }), 

    validationFunc() {
      const [filedName, value] = arguments;
      this.form[filedName] = value;
      this.$v.form[filedName].$touch();
    },

    async logIn() {
      this.$v.form.$touch();
      if (!this.$v.form.$error) {
        await this.loginAction(this.form)
          .then((response) => {
              this.$router.push(response.redirect);
            },
            error => {
              try {
                this.isShowMessage = true;
                if (error.response.data.message === "Not approved user") {
                  this.message =
                    "The user with the current E-mail is waiting for approval";
                }
                if (error.response.data.message === "Rejected user") {
                  this.message = "The user with the current E-mail is rejected";
                }
                if (error.response.data.code === 401) {
                  this.message = error.response.data.message ? error.response.data.message : 'Login error';
                }
              } catch (e) {
                console.error(e);
              }
            }
          )
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "./src/assets/scss/authGeneral";
.error-field::v-deep {
  .v-text-field--outlined > .v-input__control > .v-input__slot {
    background-color: tomato;
  }
  .v-text-field--outlined > .v-input__control > .v-input__slot .v-label {
    color: white;
  }
}
.signUpText {
  width: 100%;
  font: normal normal 600 14px Segoe UI;
  color: #164066;
  margin-top: 30px;
  transition: $transition;
  &:hover {
    color: $primary-color !important;
  }
}
.forgotPass {
  width: 100%;
  font: normal normal normal 12px/16px Segoe UI;
  color: #164066;
  text-transform: capitalize;
  margin-bottom: 10px;
}
.title {
  width: 73px;
  color: #164066;
  margin-bottom: 24px;
  font: normal normal 600 18px Segoe UI;
  text-transform: capitalize;
}
.P-login-btn {
  width: 100%;
  margin-top: 20px;
}
</style>

<style lang="scss">
  .bg-white-label {
    background-color: white;
    padding-right: 2px;
  }
</style>
